import React from "react";
import { Card, Row, Col, Nav, Container } from "react-bootstrap";
import Tabs from 'react-bootstrap/Tabs';

export default function () {
    return (
        <Container>
            <Row >
                <Col>
                    <Card style={{ width: '100%', border: 'none' }}>
                        <Card.Body>
                            <Row>
                                <Col sm={{ span: 8, offset: 2 }}>
                                    <h1 style={{ fontWeight: "bold", color: "#222" }}><span style={{ color: "#1f33a1" }}>CITMCO</span></h1>
                                    <br/>
                                    <p className="fade-in" style={{ textAlign: "justify", fontSize: "large" }}>
                                        La empresa nace de la experiencia de más de 20 años de Cimentaciones de Coatzacoalcos (CITMCO), empresa especializada en cimentación profunda realizando subcontratos en diferentes zonas de la república mexicana, posteriormente la empresa fue sumando otros servicios de construcción de obras civil, estructuras portuarias y terracerías.
                                    </p>
                                    <img style={{ marginTop:"15px", marginBottom: "15px" }} width="40%" src="https://citmco.com.mx/img/citmco-logodoble.png" />
                                    <p className="fade-in justify-content-md-center" style={{ textAlign: "justify", fontSize: "large" }}>
                                        La constante evolución y desarrollo de la empresa propicio a constituir a Construcciones de Infraestructuras Terrestres y Marítimas de Coatzacoalcos (CITMCO) implementando nuevos paradigmas de construcción con el BIM (Building Information Modeling) logrando ofrecer a nuestros clientes mejoras en la construcción con la mas lata calidad. Permitiendo llevara el desarrollo de la planeación, programación y ejecución de la obra en tiempo y forma.
                                    </p>
                                </Col>
                            </Row>                          
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}