import React from "react";
import { Container, Row, Col, Card } from 'react-bootstrap';
import Card1 from "C:/Users/cascada/Desktop/React/citmco/citmco-app/src/assets/architecture-768432_1920.jpg";

export default function () {
    return (
        <Container>
            <Row>
                <Col>
                    <Card style={{ width: '100%', border: 'none' }}>
                        <Card.Body>
                            <h1 style={{ fontWeight: "initial" }}>SERVICIOS</h1>
                            <Card.Title style={{ fontWeight: "bold", color: "#E52353" }}>CIMENTACION PROFUNDA</Card.Title>
                            <br />
                            <Row>
                                <Col>
                                    <Card style={{ width: '100%', border: 'none' }}>
                                        <Card.Img style={{ width: '100%' }} variant="top" src={Card1} />
                                        <Card.Body>
                                            <Card.Title>PILAS DE CIMENTACIÓN</Card.Title>
                                            <Card.Text className="fade-in" className="fade-in" style={{ textAlign: "justify" }}>
                                                Contamos con la experiencia y equipos de vanguardia para ejecutar los proyectos de Pilas de Cimentación para profundidades desde los 6 hasta 50 Metros con diámetros desde 0.50m hasta los 2 m.
                                                </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card style={{ width: '100%', border: 'none' }}>
                                        <Card.Img style={{ width: '100%' }} variant="top" src={Card1} />
                                        <Card.Body>
                                            <Card.Title>PILOTES PRECOLADOS</Card.Title>
                                            <Card.Text className="fade-in" style={{ textAlign: "justify" }}>
                                                Contamos con la experiencia para la fabricación y el hincado de pilotes de concreto y tubos de aceros, de diferentes secciones y longitudes.
                                                </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card style={{ width: '100%', border: "none" }}>
                                        <Card.Img style={{ width: '100%' }} variant="top" src={Card1} />
                                        <Card.Body>
                                            <Card.Title>CIMENTACIÓN EN AGUA</Card.Title>
                                            <Card.Text className="fade-in" style={{ textAlign: "justify" }}>
                                                CITMCO cuenta con la experiencia y desarrollo de procedimiento constructivo para ejecutar pilas, tablaestacas y pilotes para puentes o estructuras marinas, donde la problemática del tirante de agua provoca dificultades para la ejecución de la obra.
                                                </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card style={{ width: '100%', border: "none" }}>
                                        <Card.Img style={{ width: '100%' }} variant="top" src={Card1} />
                                        <Card.Body>
                                            <Card.Title>TABLAESTACAS DE ACERO</Card.Title>
                                            <Card.Text className="fade-in" style={{ textAlign: "justify" }}>
                                                Contamos con proveedores de Tablestaca Metálica de el País; así como la experiencia para su hincado y extracción con los equipos de mayor potencia para realizar los proyectos de mayor complejidad.
                                                </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card style={{ width: '100%', border: 'none' }}>
                                        <Card.Body>
                                            <Card.Title style={{ fontWeight: "bold", color: "#E52353" }}>CONSTRUCCIÓN</Card.Title>
                                            <Card.Text>
                                                Abarcando las especialidades:
                                </Card.Text>
                                            <Row>
                                                <Col>
                                                    <Card style={{ width: '100%', border: 'none' }}>
                                                        <Card.Img style={{ width: '100%' }} variant="top" src={Card1} />
                                                        <Card.Title>PUENTES</Card.Title>
                                                        <Card.Body>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col>
                                                    <Card style={{ width: '100%', border: 'none' }}>
                                                        <Card.Img style={{ width: '100%' }} variant="top" src={Card1} />
                                                        <Card.Title>CARRETERAS</Card.Title>
                                                        <Card.Body>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Card style={{ width: '100%', border: "none" }}>
                                                        <Card.Img style={{ width: '100%' }} variant="top" src={Card1} />
                                                        <Card.Title>ESTRUCTURAS</Card.Title>
                                                        <Card.Body>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col>
                                                    <Card style={{ width: '100%', border: "none" }}>
                                                        <Card.Img style={{ width: '100%' }} variant="top" src={Card1} />
                                                        <Card.Title>PUERTOS MARÍTIMOS</Card.Title>
                                                        <Card.Body>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card style={{ width: '100%', border: "none" }}>
                                        <Card.Body>
                                            <br />
                                            <Card.Title style={{ fontWeight: "bold", color: "#E52353" }}>MODELADO BIM.</Card.Title>
                                            <br />
                                            <Card.Img style={{ width: '100%' }} variant="top" src={Card1} />
                                            <Card.Body>
                                                <Card.Text className="fade-in" style={{ textAlign: "justify" }}>
                                                    En CITMCO implementamos nuevas formas de trabajos colaborativos, basado en el uso de la tecnología BIM para apórtales a nuestros clientes un modelo digital de su proyecto ayudándolo a la toma de decisiones en cuatro puntos fundamentales: estrategia, proceso, sistemas y capital humano.
                                                    Despejando incertidumbres y problemas en obra. Gracias a softwares especializados en cada fase del proyecto.
    </Card.Text>
                                            </Card.Body>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row >
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}
